import { useEffect } from "react";
import styles from "./Statement.module.css";
import SEO from "../../components/seo/seo";
import { seoStatement } from "../../components/seo/seoData";
import clsx from "clsx";

const Statement = () => {
  useEffect(() => {
    const statementPhoto = document.getElementById("statement-photo");
    const preventEvent = (event: any) => {
      event.preventDefault();
    };

    statementPhoto &&
      statementPhoto.addEventListener("contextmenu", preventEvent);

    return () => {
      statementPhoto &&
        statementPhoto.removeEventListener("contextmenu", preventEvent);
    };
  }, []);

  return (
    <div className={styles.section}>
      <SEO title={seoStatement.title} description={seoStatement.description} />
      <img
        id="statement-photo"
        draggable={false}
        src={require("../../assets/images/statement-cover.jpg")}
        className={styles.image}
        alt="artist portrait"
        width="300"
        height="auto"
      />

      <div className={styles.text_box}>
        <p className={styles.text}>
          У всего есть{" "}
          <span className={clsx(styles.text, styles.bold)}> душа</span>.
        </p>
        <p className={styles.text}>
          Наш мир полон{" "}
          <span className={clsx(styles.text, styles.bold)}> случайностей</span>,{" "}
          <span className={clsx(styles.text, styles.bold)}>хаоса</span> и{" "}
          <span className={clsx(styles.text, styles.bold)}>абсурда</span>.
        </p>
        <p className={styles.text}>
          Сохраняй{" "}
          <span className={clsx(styles.text, styles.bold)}> мир в душе</span> в
          любой ситуации.
        </p>

        <p className={styles.text}>
          Многие вещи вокруг нас обладают{" "}
          <span className={clsx(styles.text, styles.bold)}> загадочностью</span>{" "}
          и <span className={clsx(styles.text, styles.bold)}>символизмом</span>.
        </p>

        <p className={styles.text}>
          Немного <span className={clsx(styles.text, styles.bold)}>юмора</span>{" "}
          всегда кстати.
        </p>
        <p className={styles.text}>
          <span className={clsx(styles.text, styles.bold)}>Информацию</span> и{" "}
          <span className={clsx(styles.text, styles.bold)}>энергию</span> можно
          почувствовать и даже почти увидеть.
        </p>
        <p className={styles.text}>
          Наслаждайся{" "}
          <span className={clsx(styles.text, styles.bold)}>
            маленькими радостями{" "}
          </span>
          жизни.
        </p>
        <p className={styles.text}>
          Иностранные
          <span className={clsx(styles.text, styles.bold)}> языки</span> могут
          открыть двери в абсолютно новые{" "}
          <span className={clsx(styles.text, styles.bold)}>миры</span>.
        </p>
        <p className={styles.text}>
          Сочетать{" "}
          <span className={clsx(styles.text, styles.bold)}>цвета </span>
          очень <span className={clsx(styles.text, styles.bold)}>весело</span>.
        </p>
      </div>
    </div>
  );
};

export default Statement;
