import { TMenuLink } from "../../utils/types";
import { MenuUI } from "../ui/menuUI/MenuUI";

const menuMainLinks: TMenuLink[] = [
  { name: "Об авторе", path: "/about" },
  { name: "Галерея", path: "/gallery" },
  { name: "Контакты", path: "/contact" },
];

const MenuMain = () => MenuUI(menuMainLinks);

export default MenuMain;
