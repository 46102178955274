import { TProject } from "../utils/types";

export const projects: TProject[] = [
  {
    id: "catasian",
    name: "котазиаты",
    year: "2017",
    path: "/gallery/2017/catasian",
  },
  {
    id: "catgirls",
    name: "котодевушки",
    year: "2017",
    path: "/gallery/2017/catgirls",
  },
  {
    id: "animalasian",
    name: "животные",
    year: "2017",
    path: "/gallery/2017/animalasian",
  },
  {
    id: "best-2018",
    name: "лучшее из 2018",
    year: "2018",
    path: "/gallery/2018/best-2018",
  },
  {
    id: "best-2019-2020",
    name: "лучшее из 2019-2020",
    year: "2019-2020",
    path: "/gallery/2019-2020/best-2019-2020",
  },
  {
    id: "fish-and-birds",
    name: "птицы и рыбы",
    year: "2024",
    path: "/gallery/2024/fish-and-birds",
  },

  {
    id: "catyoga",
    name: "котойога",
    year: "2024",
    path: "/gallery/2024/catyoga",
  },
  {
    id: "catasian-world",
    name: "мир котазиатов",
    year: "2024",
    path: "/gallery/2024/catasian-world",
  },
];
