import { useEffect } from "react";
import styles from "./Bio.module.css";
import SEO from "../../components/seo/seo";
import { seoBio } from "../../components/seo/seoData";
import clsx from "clsx";

const Bio = () => {
  useEffect(() => {
    const artistPhoto = document.getElementById("artist-photo");
    const preventEvent = (event: any) => {
      event.preventDefault();
    };

    artistPhoto && artistPhoto.addEventListener("contextmenu", preventEvent);

    return () => {
      artistPhoto &&
        artistPhoto.removeEventListener("contextmenu", preventEvent);
    };
  }, []);

  return (
    <div className={styles.section}>
      <SEO title={seoBio.title} description={seoBio.description} />
      <img
        id="artist-photo"
        draggable={false}
        src={require("../../assets/images/artist-portrait.jpg")}
        className={styles.image}
        alt="artist portrait"
        width="300"
        height="auto"
      />

      <div className={styles.text_box}>
        <p className={styles.text}>
          Меня зовут{" "}
          <span className={clsx(styles.text, styles.bold)}>Женя Хитрова</span> и
          я <span className={clsx(styles.text, styles.bold)}> художник</span>,
          полиглот и любитель животных с Тихоокеанского побережья России.
        </p>
        <p className={styles.text}>
          Я <span className={clsx(styles.text, styles.bold)}>люблю</span>{" "}
          творчество с самого раннего детства, а профессионально начала
          развивать карьеру художника в{" "}
          <span className={clsx(styles.text, styles.bold)}>2015</span> году,
          когда я переехала в{" "}
          <span className={clsx(styles.text, styles.bold)}>Шанхай</span>, Китай,
          где я принимала участие в различных групповых{" "}
          <span className={clsx(styles.text, styles.bold)}> выставках</span>, а
          также участвовала в благотворительных мероприятиях.
        </p>
        <p className={styles.text}>
          В <span className={clsx(styles.text, styles.bold)}>2017</span> году в
          моем маленьком
          <span className={clsx(styles.text, styles.bold)}> скетчбуке</span> на
          борту самолета, летящего из Гонконга на Тайвань, родилось мое
          фирменное{" "}
          <span className={clsx(styles.text, styles.bold)}>
            Котазиатское лицо
          </span>{" "}
          . После этого я начала рисовать различных{" "}
          <span className={clsx(styles.text, styles.bold)}>существ</span> с этим
          загадочным лицом, которое впоследствии стало абсолютным центром моей
          творческой практики.
        </p>
        <p className={styles.text}>
          В <span className={clsx(styles.text, styles.bold)}>2019</span> году я
          вернулась назад в свой родной город, где продолжаю жить и развивать
          новые идеи с Котазиатским лицом.
        </p>
        <p className={styles.text}>
          В начале <span className={clsx(styles.text, styles.bold)}>2025</span>{" "}
          года я начала новый проект под названием{" "}
          <span className={clsx(styles.text, styles.bold)}>
            "Пантеон Случайных Духов"
          </span>
          , в котором я рисую Духов самых разнообразных вещей и явлений
          окружающего нас мира, так как верю в то, что{" "}
          <span className={clsx(styles.text, styles.bold)}>
            у всего есть душа.
          </span>
        </p>
      </div>
    </div>
  );
};

export default Bio;
