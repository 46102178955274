export const seoHome = {
  title: "Главная | Художник Женя Хитрова",
  description:
    "Домашняя страница художница Женя Хитрова, современное русское искусство, картины акриловыми красками.",
};

export const seoBio = {
  title: "Биография | Художник Женя Хитрова",
  description:
    "Краткая биография художница Женя Хитрова, современное русское искусство, картины акриловыми красками.",
};

export const seoStatement = {
  title: "Принципы | Художник Женя Хитрова",
  description:
    "Заявление художника художница Женя Хитрова, современное русское искусство, картины акриловыми красками.",
};

export const seoCV = {
  title: "Резюме | Художник Женя Хитрова",
  description:
    "Резюме художница Женя Хитрова, современное русское искусство, картины акриловыми красками.",
};

export const seoContact = {
  title: "Контакты | Художник Женя Хитрова",
  description:
    "Контакты художница Женя Хитрова, современное русское искусство, картины акриловыми красками. Социальные сети, электронная почта",
};
