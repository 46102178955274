import SEO from "../../components/seo/seo";
import { seoCV } from "../../components/seo/seoData";
import styles from "./Cv.module.css";

const Cv = () => {
  return (
    <div className={styles.section}>
      <SEO title={seoCV.title} description={seoCV.description} />
      <div className={styles.text_box}>
        <h3 className={styles.section_title}>общая информация</h3>
        <p className={styles.text}>Женя Хитрова, род. 1987 </p>
        <p className={styles.text}>Живет и работает в г. Находка, Россия</p>
      </div>
      <div className={styles.text_box}>
        <h3 className={styles.section_title}>Групповые выставки</h3>
        <h4 className={styles.text_year}>2021</h4>
        <p className={styles.text}>
          Listen Exhibition: Smombies, curated by Heather Cai, YCAC, M50,
          Shanghai, China.
        </p>
        <h4 className={styles.text_year}>2020</h4>
        <p className={styles.text}>
          Behold Exhibition, THE WHO? of 2020, curated by Heather Cai, No Name
          Studio, Shanghai, China.
        </p>
        <h4 className={styles.text_year}>2019</h4>
        <p className={styles.text}>
          Project collaboration with Square Gallery, Shanghai, China.
        </p>
        <h4 className={styles.text_year}>2018</h4>
        <p className={styles.text}>
          Project collaboration with U Gallery (prev. Uconcept Gallery),
          Shanghai, China.
        </p>
        <h4 className={styles.text_year}>2017</h4>
        <p className={styles.text}>
          MUSE 1-3-5, Curated by Lulu, MUSE gallery & art lab, Shanghai, China.
        </p>
      </div>

      <div className={styles.text_box}>
        <h3 className={styles.section_title}>Коллекции</h3>
        <p className={styles.text}>
          Работы автора находятся в частных коллекциях в России, США, Китае,
          Австралии, Франции, Швейцарии и Японии.
        </p>
      </div>
      <div className={styles.text_box}>
        <h3 className={styles.section_title}>Образование</h3>
        <p className={styles.text}>
          2009, Дальневосточный Государственный Университет, Английская
          филология, Владивосток, Россия.
        </p>
      </div>
    </div>
  );
};

export default Cv;
